import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContainerService } from './container.service';
import { WebAdminResponse, AppointmentResponse, ClentSearchResponse } from '../shared/models/response.model';
import { ContainerAddModel } from '../shared/models/container.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AppointmentService } from '../appointments/appointment.service';
import { AppointmentAddModel } from '../shared/models/appointment.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EditAppointmentComponent } from '../appointments/edit-appointment/edit-appointment.component';
import { CallComponent } from '../video/call/call.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { ClientsService } from '../clients/clients.service';
//import { SidebarService } from '../sidebar/sidebar.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators'; 
@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit {
  @ViewChild('clientListModal') clientListModal: ElementRef;

  adminDashboardData: ContainerAddModel;
  isLoaded: boolean = false;
  feesCommissionsByData: any = [];
  feesCommissionsByYears: any = [];
  clientByTypeData: any = [];
  clientByTypes: any = [];
  pendingAppointmentList: AppointmentAddModel[];
  pendingAppointmentOne: AppointmentAddModel;
  isLoadedPendingAppointments: boolean;
  modalRef: BsModalRef;
  modalRefVideo: BsModalRef;
  appointmentList: AppointmentAddModel[];
  companyCode: any;
  isLoading: boolean = false;
  simpleSearchLoading: boolean = false;

  public searchClientForm: FormGroup;
  public searchResults: any;
  public clientId: any;

  public showBoundaryLinks: boolean = true;
  public showDirectionLinks: boolean = true;
  public totalRecordsCount: number;
  public currentPage: number;
  public itemsPerPage: number = 10; // Define default items per page
  public openAgain = true;

  //private sidebarService: SidebarService, // private ngxService: NgxUiLoaderService,
  constructor(private config: NgbModalConfig, private ngbModalService: NgbModal, private containerService: ContainerService, private appointmentService: AppointmentService, private modalService: BsModalService, private cookieService: CookieService, private router: Router, private fb: FormBuilder, private clientsService: ClientsService, private ngxService: NgxUiLoaderService) { 
    this.config.size = 'xl';
    this.config.centered = true;
    this.config.backdrop = 'static';
  }

  ngOnInit() {
    //this.ngxService.start();
    this.companyCode = this.cookieService.get('companyCode');
    // this.GetAdminData();
    // this.getPendingAppointments();
    // comment by thilina, request by DevT (uncomment, DevT remove the service)
    //this.sidebarService.preloaderAction(true);
    this.initForm();
  }

  initForm() {
    this.searchClientForm = this.fb.group({
      clientName: ['', [Validators.required, Validators.minLength(3)]],
      xeppoCode: [''],
      clientCode: [''],
      mobileNumber: [''],
      email: [''],
      advanced: [false]
    });
    this.isLoaded = true;
    
    this.searchClientForm.get('clientName').valueChanges.pipe(
      debounceTime(1000),
    ).subscribe(data => {
      if (!this.searchClientForm.value.advanced) {
        this.searchClients()
      }
    }); 
  }

  GetAdminData(){
    
    //console.log(" sidebarService.preloaderAction ");
    this.containerService.getWebAdminDashboard(this.companyCode).subscribe((adminData:WebAdminResponse)=>{
   
      this.adminDashboardData = adminData.response;
      // console.log("admin dashboard details ==========");
      // console.log(this.adminDashboardData);

      if(this.adminDashboardData && this.adminDashboardData.feesCommissionsByYear){
        this.adminDashboardData.feesCommissionsByYear.forEach(element => {
          this.feesCommissionsByData.push (element.total);
          this.feesCommissionsByYears.push (element.year);
        });
      }

      if(this.adminDashboardData && this.adminDashboardData.clientByType){
        this.adminDashboardData.clientByType.forEach(element => {
          this.clientByTypeData.push (element.count);
          this.clientByTypes.push (element.type);
        });
      }
      
      // console.log(this.clientByTypeData, 'clientByTypeData');
      this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message); 
      });
  }

  getPendingAppointments(){
    this.appointmentService.getPendingAppointments().subscribe((data:AppointmentResponse)=>{     
      this.pendingAppointmentList = data.response;      
      this.pendingAppointmentOne = data.response[0]; 
      //console.log('Appointment list pending ---------');
      //console.log(this.pendingAppointmentList);
      //console.log(this.pendingAppointmentOne);
      
      this.isLoadedPendingAppointments = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });   
  }

  formatLocalTime(date){
    // console.log(date);
    return new Date(date+'Z');
  }

  openEditModal(appointment:any) {  
    //console.log(1111111111);  
    //console.log(appointment);  

    let scheduledDat = appointment.sheduledDate;
      
    //console.log(appointment.sheduledDate);
      
    this.modalRef = this.modalService.show(EditAppointmentComponent,  {
      initialState: {
        title: 'Edit Appointment',
        data: {
          appointment:appointment
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      //class: "modal-dialog modal-lg modal_lg_width appointment_modal"
      class: "modal-dialog modal-lg modal_lg_width"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getAppointmentList(); 
        this.router.navigate(['dashboard']);     
      }
    });

  }

  openCallModal(appointment:any) {

    let initialState = {
      title: 'Call with user',
      data: {
        appointment:appointment
      },
    };

    this.modalRef = this.modalService.show(CallComponent,  {
      initialState,
      class: "video_modal",      
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true
    });
  
    this.modalRef.content.event.subscribe(result => {      
      if (result == 'call_disconnected') {
        //console.log("8888888");
        this.modalRef.hide();
        
        //console.log("Success");
        window.location = window.location;  
      }
    });
  }

  getAppointmentList(){
    this.appointmentService.appointmentList().subscribe((data:AppointmentResponse)=>{     
      this.appointmentList = data.response;
      
      // .filter(function(appointment){
      //   return appointment.sheduledDate == new Date(appointment.sheduledDate+'Z');;
      // });;      

      //console.log('Appointment list for ---------');
      //console.log(this.appointmentList);
      this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  public wealthChartOptions = {
    responsive: true,
          tooltips: {
              displayColors: false,
              callbacks: {
                  label: function(e, d) {
                      // return '${e.xLabel} : ${e.yLabel}'
                  },
                  title: function() {
                      return;
                  }
              }
          },
          legend: {
              display: false
          },
          scales: {
              xAxes: [{
                  gridLines: {
                      display: false,
                  },
              }],
              yAxes: [{
                  ticks: {
                      padding: 10,
                      stepSize: 5000,
                      max: 20000,
                      min: 0,
                  },
                  gridLines: {
                      display: true,
                      drawBorder: false,
                      lineWidth: 1,
                      zeroLineColor: '#e5e5e5',
                  }
              }]
          }
  };
  public wealthChartLabels = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"];
  public wealthChartType = 'line';
  public wealthChartLegend = true;
  public lineChartColors = [
    {
      borderColor: "#15a9c8"
    },
  ];
  public wealthChartData = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      borderWidth: 4,
      borderColor: '#15a9c8',
      pointBackgroundColor: "#FFF",
      pointBorderColor: "#15a9c8",
      pointHoverBackgroundColor: "#FFF",
      pointHoverBorderColor: "#15a9c8",
      pointRadius: 0,
      pointHoverRadius: 6,
      fill: false,
    }
  ];





  public clientTypeChartLabels = this.clientByTypes; //["Individual", "Fund", "Company", "Local Body", "Non Profit", "Other"];
  public clientTypeChartData = this.clientByTypeData; //[45, 25, 15, 8, 5, 2];
  public clientTypeChartType = 'doughnut'; //hitRateDoughnut
  public clientTypeChartLegend = true;
  public clientTypeChartColors = ["#1c96c5", "#20a7db","#62c1e5", "#a0d9ef","#5ac3eb", "#3994b7"];

  public doughnutColors:any[] = [
    { backgroundColor: ["#1c96c5", "#20a7db","#62c1e5", "#a0d9ef","#5ac3eb", "#3994b7"] }
  ];

  public clientTypeChartOptions = {
    responsive: true,
    title: {
        display: false
    },
    legend: {
        display: false
    },
    layout: {
        padding: {
            left: 16,
            right: 16,
            top: 16,
            bottom: 16
        }
    },
    cutoutPercentage: 92,
    animation:{
        animateScale : false,
        duration: 2500
    }
    };




  
    // public doughnutChartPlugins = [{
    //   beforeDraw(chart) {
    //     const ctx = chart.ctx;
    //     const txt = 'CLIENTS';
  
    //     //Get options from the center object in options
    //     const sidePadding = 60;
    //     const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
  
    //     ctx.textAlign = 'center';
    //     ctx.textBaseline = 'middle';
    //     const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    //     const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2.3);
  
    //     //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    //     const stringWidth = ctx.measureText(txt).width;
    //     const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
  
    //     // Find out how much the font can grow in width.
    //     const widthRatio = elementWidth / stringWidth;
    //     const newFontSize = Math.floor(30 * widthRatio);
    //     const elementHeight = (chart.innerRadius * 2);
  
    //     // Pick a new font size so it will not be larger than the height of label.
    //     const fontSizeToUse = Math.min(newFontSize, elementHeight);
  
    //     ctx.font = fontSizeToUse/4 + 'px Arial';
    //     ctx.fillStyle = '#252525';
  
    //     // Draw text in center        
    //     ctx.fillText('FORTRESS', centerX, centerY);

    //     ctx.textAlign = 'center';
    //     ctx.textBaseline = 'middle';
    //     ctx.font = fontSizeToUse/2 + 'px Arial';
    //     ctx.fillStyle = '#15a9c8';
    //     ctx.fillText('CLIENTS', centerX, (centerY+45) );
    //   }
    // }];


    public feesChartOptions = {
      elements: {
        rectangle: {
            borderWidth: 2,
            borderColor: 'rgb(0, 255, 0)',
            borderSkipped: 'bottom'
        }
    },
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration:500,
    legend: {
        position: 'top',
        display: false
    },
    scales: {
        xAxes: [{
            display: true,
            gridLines: {
                color: "#f3f3f3",
                drawTicks: false,
            },
            scaleLabel: {
                display: true,
            }
        }],
        yAxes: [{
            display: true,
            gridLines: {
                color: "#f3f3f3",
                drawTicks: false,
            },
            scaleLabel: {
                display: true,
            }
        }]
    },
      title: {
        display: false,
        text: 'Chart.js Bar Chart'
    }            
    };

    public feesChartLabels = this.feesCommissionsByYears; //["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Nov"];
    public feesChartType = 'bar';
    public feesChartLegend = true;
    public feesChartColors = [
      {
        borderColor: "#15a9c8",
        backgroundColor: '#15a9c8',
      },
    ];
    public feesChartData = [
      {
        data: this.feesCommissionsByData, //[65, 73, 71, 64, 67, 72, 68, 62, 67, 69, 54],
        borderWidth: 4,
        borderColor: '#15a9c8',
        pointBackgroundColor: "#FFF",
        pointBorderColor: "#15a9c8",
        pointHoverBackgroundColor: "#FFF",
        pointHoverBorderColor: "#15a9c8",
        pointRadius: 0,
        pointHoverRadius: 6,
        fill: true,
      }
    ];

    simpleSearch() {
      if (!this.searchClientForm.value.advanced) {
        this.searchClients()
      }
    }

  searchClients(content?, event?) {
    // if(!event) {
    //   this.openAgain = true;
    // }
    // this.currentPage = event?.page || 1;
    if (!this.searchClientForm.value.advanced) {
      this.simpleSearchLoading = true;
    } else {
      this.simpleSearchLoading = false;
    }

    this.searchClientForm.markAllAsTouched(); //enter click change detection
    this.clientId = null;
    this.searchResults = null;
    const formData = this.searchClientForm.value;

    const reqData = {
      name: formData.clientName ? formData.clientName.trim() : null,
      code: formData.xeppoCode ? formData.xeppoCode : null,
      email: formData.email ? formData.email : null,
      mobileNumber: formData.mobileNumber ? formData.mobileNumber: null,
      isAdvanceSearch: formData.advanced,
      companyCode: this.companyCode,
    }

    if (this.searchClientForm.valid && (formData.clientName || formData.xeppoCode || formData.email || formData.mobileNumber)) {
      this.ngxService.start();
      this.isLoading = true;
      if (this.searchClientForm.value.advanced) {
      this.clientsService.clientSearch(reqData).subscribe((data: ClentSearchResponse) => {          
        this.ngxService.stop();
        if (data.response) {
          this.isLoading = false;
          this.searchResults = data.response.sort((a, b) => ((a.firstName ? a.firstName.trim() : a.firstName)+ (a.middleName ? a.middleName.trim() : a.middleName) + (a.lastName ? a.lastName.trim() : a.lastName)).localeCompare((b.firstName ? b.firstName.trim(): b.firstName)+ (a.middleName ? a.middleName.trim() : a.middleName) + (b.lastName ? b.lastName.trim() : b.lastName)))
          // this.totalRecordsCount = data.response.totalRecords;
          // if(this.openAgain) {
            this.ngbModalService.dismissAll();
            if(content) {
              this.ngbModalService.open(content, { windowClass: 'client-list'});
            }
          // }
          // this.openAgain = false;
        } else {
          this.searchResults = null
          this.isLoading = false;
        }
      },
      (err: HttpErrorResponse) => {
        this.searchResults = null
        this.isLoading = false;
        this.ngxService.stop();
      });
    } else {
      this.clientsService.clientSimpleSearch(reqData).subscribe((data: any) => {          
        this.ngxService.stop();
        if (data.response) {
          this.isLoading = false;
          this.searchResults = data.response.sort((a, b) => ((a.firstName ? a.firstName.trim() : a.firstName)+ (a.lastName ? a.lastName.trim() : a.lastName)).localeCompare((b.firstName ? b.firstName.trim(): b.firstName)+ (b.lastName ? b.lastName.trim() : b.lastName)));
        } else {
          this.searchResults = null
          this.isLoading = false;
        }
      },
      (err: HttpErrorResponse) => {
        this.searchResults = null
        this.isLoading = false;
        this.ngxService.stop();
      });
    }
  }
}

  setMinValidation(value) {
    if (this.searchClientForm.get('advanced').value) {
      if(value.length > 0) {
        this.searchClientForm.get('clientName').setValidators([Validators.minLength(3)]);
      } else {
        this.searchClientForm.get('clientName').clearValidators();
      }
      this.searchClientForm.controls['clientName'].updateValueAndValidity();
    }
  }

  trimValue(name, value) {
    this.searchClientForm.get(name).setValue(value.trim());
    this.searchClientForm.updateValueAndValidity();
  }

  hideModal() {
    this.ngbModalService.dismissAll();
  }

  submit(clientId: any) {
    this.clientId = clientId;
    if (this.searchClientForm.valid && this.clientId) {
      this.router.navigate(['/client/view'], { queryParams: { id: clientId } })
      this.clientId = null;
      this.ngbModalService.dismissAll();
    }
  }

  removeValidation() {
    if (this.searchClientForm.controls['advanced'].value) {
      this.searchClientForm.get('clientName').clearValidators();
      this.searchClientForm.get('clientName').setValidators([Validators.minLength(3)]);
    } else {
      this.searchClientForm.get('clientName').setValidators([Validators.required, Validators.minLength(3)]);
    }
    this.searchClientForm.controls['clientName'].updateValueAndValidity();
  }
}
